@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: #1e1b2e;
}

.App {
  text-align: center;
  font-family: "Poppins", sans-serif;
  padding: 2rem 0;
}

.header {
  font-size: 2rem;
  font-weight: 500;
}
