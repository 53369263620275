.success {
  margin: 6rem 0;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 2rem;
  align-items: center;
  font-size: 1.3rem;
}

.iconsContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}

.icon {
  width: 3rem;
  height: 3rem;
  color: #cd4bf0;
}

.google {
  width: 263.6px;
  height: 100px;
}

.apple {
  width: 218px;
  height: 68px;
}
