.inputContainer {
  margin: 10rem 0;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.input {
  width: 18rem;
  height: 3rem;
  color: #fff;
  font-family: "Poppins", sans-serif;
  font-size: 0.9rem;

  outline: none;

  padding: 0 0.5rem;

  border: 0.1rem solid #cd4bf090;
  border-radius: 0.8rem;
  margin: 0.5rem 0;
}

.input:focus {
  border: 0.1rem solid #cd4bf0;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Poppins", sans-serif;
  width: 10rem;
  height: 2.5rem;
  border-radius: 2rem;
  color: #fff;
  border: none;
  background: linear-gradient(to right, #cd4bf0, #007dfc);
  cursor: pointer;
  margin-top: 1rem;
}

.button:hover {
  filter: brightness(1.1);
  transition-duration: 1s;
}

.error {
  color: #fc0056;
  padding: 0.5rem 0;
}
