@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loading-spinner {
  width: 1rem;
  height: 1rem;
  border: 0.2rem solid #f3f3f3;
  border-top: 0.2rem solid transparent;
  border-radius: 50%;
  animation: spinner 1s linear infinite;
  background-color: transparent;
}

.spinner-container {
  display: grid;
  justify-content: center;
  align-items: center;
  background-color: transparent;
}
